html, body {
  overflow-x: hidden;
  width: 100%;
}

a {
  color: #108ac3;
}

.cs-all-caps {
  text-transform: uppercase;
}

.cs-link {
  cursor: pointer;
}

.cs-link:hover {
  text-decoration: underline;
}

.cs-error {
  color: #f44336;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
}

.cs-avatar {
  margin-right: 0 !important;
}

.cs-right-box {
  justify-content: center;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  padding: 10px !important;
}

.MuiAutocomplete-endAdornment {
  top: auto !important;
}

.link-menu {
  color: #172b4d !important;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}

.community-tags .MuiChip-root {
  margin: 3px;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.au-icon-btn {
  padding: 5px !important;
}

.table-head {
  background-color: #ffffff !important;
}

.card-image {
  transition: 0.5s;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  min-height: 150px;
}

.card-image:hover {
  opacity: 0.7;
}

.dialog-toolbar {
  justify-content: space-between;
}

.dialog-image {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
}

.dialog-image-container {
  padding-top: 60px;
}

.dialog-image-container > .MuiDialog-container > .MuiDialog-paperFullScreen {
  background-color: #fff0 !important;
}

.dialog-camera {
  max-height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.dialog-camera > .MuiDialog-container > .MuiDialog-paperFullScreen {
  overflow: hidden !important;
}

.camera-app {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  transition: 0.4s;
  z-index: 5;
}

.result img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.result.hasPhoto {
  left: 0;
}

.camera-buttons {
  position: absolute !important;
  right: 15px;
  top: 70px;
  left: auto;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
}

.camera-buttons .button {
  margin-bottom: 10px;
}

.react-html5-camera-photo {
  max-height: 100vh;
}

.react-html5-camera-photo-fullscreen>video {
  object-fit: cover;
}

.detail-title {
  flex-direction: column;
}

@media screen and (min-width: 500px) {
  .detail-title {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .camera-buttons {
    top: 100px;
  }
}


#container-circles {
  bottom: 150px;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb li, .breadcrumb li p {
  font-size: 14px;
}

.box-button {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}

.box-button button {
  min-width: 90px;
}

.box-page-section {
  padding: 2em;
}

.box-form-button {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}

.radio-legend {
  margin-top: 5px;
  margin-right: 20px;
}

.radio-group {
  padding: 10px 20px;
}

.assessment-header {
  display: flex;
  width: 100%;
}

.section-nav-button-cont {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}

.form-field-container {
  margin: 10px 0;
}

.indent {
  margin-left: 50px;
}

.field-label {
  align-items: center;
  display: flex;
  height: 100%;
  margin-right: 20px;
}

.other-field-label {
  display: inline-flex;
  padding: 10px;
  padding-left: 0;
}

.dashed {
  border: none;
  height: 1px;
  background:rgba(0, 0, 0, 0.12);
  background: repeating-linear-gradient(90deg,rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12) 6px,transparent 6px,transparent 12px);
}

.whitespace {
  height: 30px;
  display: block;
}

.overview tr {
  height: 50px;
}

.overview-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.form-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.box-page {
  min-height: 100%;
}

.submenu {
  padding-left: 1em !important;
}

.list-toolbar {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.search-bar-container {
  max-width: 600px;
  align-items: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-end;
}

.search-bar {
  padding: 10px;
  width: 300px !important;
}

@media screen and (min-width: 500px) {
  .overview-header {
    flex-direction: row;
  }

  .box-page {
    padding: 2em;
  }

  .submenu {
    padding-left: 3em !important;
  }

  .search-bar-container {
    flex-direction: row;
  }
}

@media screen and (min-width: 700px) {
  .list-toolbar {
    flex-direction: row;
  }
}

@media screen and (min-width: 800px) {
  .search-bar {
    width: 400px !important;
  }
}

.block {
  padding: 20px 0;
}

.indent {
  padding-left: 50px;
}

.footer {
  padding: 20px 0;
  top: auto;
  bottom: 0;
}

.app-bar {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  margin: '0 auto';
}

.emphasized {
  font-weight: 500;
}

.Mui-selected {
  background-color: #f0f7ff !important;
  color: #1b81e8 !important;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
}

.sidebar-footer {
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0;
  padding: 24px;
}

.disabled-link {
  pointer-events: none;
}

.report {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 3px 4px -2px rgb(0 0 0 / 25%);
  border-radius: 12px;;
  margin: 24px 0;
  padding: 24px;
}